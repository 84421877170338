<template>
<div>
    <div class="row justify-content-center py-16 px-8 py-md-16 px-md-0 bgi-size-cover bgi-position-center" style="background: #2a84a6 url('')">
    <div class="col-md-6 text-white text-center">
      <div class="d-flex flex-center">
        <div class="d-flex flex-column px-0 order-2 order-md-1">
            <h2 class="display-3 font-weight-boldest order-1">Bandi e Collaborazioni</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center py-8 px-8 px-md-0 bg-default">
    <div class="col-xl-6">
        <div class="row">
            <div class="col-xl-12">
            <div class="card mt-5">
               
                <div class="card-body">
                    <p><b>06 AGOSTO 2021 - ore 10:30 </b> 
                    <span class="ml-5 badge badge-danger text-white">GARA CHIUSA</span>
                    </p>
                    <h3><b>E-democracy tool - Project Mix the bubbles<br>
                    <i>Development &amp; Maintenance</i></b></h3>
                    <p>E-democracy tool design and management; configuration, development and and helpdesk for technical maintenance of BiPart Platform 
                    </p>
                </div>
                <div class="card-footer">
                    <!-- <a href="/assets/front/images/bipart/download/DARE_Risultati_EmpavilleRolePlayGame.pdf" class="font-weight-bold mr-3" target="blank">RISULTATI GARA <i class="far fa-arrow-alt-circle-right"></i></a>  -->
                    <a href="/assets/front/images/bipart/download/MTB_edemocracytool-avviso.pdf" class="font-weight-bold mr-3 btn btn-primary " target="blank">DOWNLOAD <i class="far fa-arrow-alt-circle-right"></i></a> 
                </div>
            </div>
            </div>
            <div class="col-xl-12">
                <div class="card mt-5">
                <div class="card-body">
                    <p><b>28 FEBBRAIO 2020 - ore 10:30 </b> 
                    <span class="ml-5 badge badge-danger text-white">GARA CHIUSA</span>
                    </p>
                    <h3><b>Empaville Role Play Game Design<br>
                    <i>Sviluppo e assistenza software</i></b></h3>
                    <p>Affidamento per la realizzazione del servizio di sviluppo di funzionalità gamification per l’applicativo empaville.bipart.it e implementazione delle aree community e deliberative con funzionalità avanzate, refactoring DB e restyling UI-UX della piattaforma www.bipart.it
                    </p>
                </div>
                <div class="card-footer">
                    <!-- <a href="/assets/front/images/bipart/download/DARE_Risultati_EmpavilleRolePlayGame.pdf" class="font-weight-bold mr-3" target="blank">RISULTATI GARA <i class="far fa-arrow-alt-circle-right"></i></a>  -->
                <a href="/assets/front/images/bipart/download/DARE_Risultati_EmpavilleRolePlayGame.pdf" class="btn btn-primary font-weight-bold mr-1 mt-1" target="blank">RISULTATI GARA <i class="far fa-arrow-alt-circle-right"></i></a> 
                    <a href="/assets/front/images/bipart/download/DARE_Avviso_EmpavilleRolePlayGame.pdf" class="btn btn-primary font-weight-bold mr-1 mt-1" target="blank">SCARICA AVVISO <i class="far fa-arrow-alt-circle-right"></i></a>   
                    <a href="/assets/front/images/bipart/download/avviso-edemocracytool-allegatoA.pdf" class="btn btn-primary font-weight-bold mt-1" target="blank">SCARICA ALLEGATO A <i class="far fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "job",
  data() {
    return { };
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ]),
    get_block5(){
      return ((this.getOrganization.data && this.getOrganization.data.block5) ? this.getOrganization.data.block5 : '');
    }

  },
};
</script>


<style scoped>

</style>
